import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "lib/firebase";
import MDBox from "components/MDBox";
import { Grid, Typography, CircularProgress } from "@mui/material";

/**
 * Statik sayfaların içeriğini Firebase Firestore'dan alır ve
 * sayfa bulunamazsa hata mesajını gösterir.
 *
 * @returns {JSX.Element}
 */
function StaticPage() {
  // URL'den slug'ı manuel olarak çıkaralım
  const location = useLocation();
  const navigate = useNavigate();
  const [pageContent, setPageContent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // URL'den slug'ı ayıklama
  const pathSegments = location.pathname.split("/");
  const extractedSlug = pathSegments[pathSegments.length - 1];

  useEffect(() => {
    const fetchPageContent = async () => {
      try {
        if (!extractedSlug) {
          //console.log("Slug değeri bulunamadı");
          setError("Sayfa URL'si eksik");
          setLoading(false);
          return;
        }

        const pagesRef = collection(db, "staticPages");
        const pageQuery = query(pagesRef, where("slug", "==", extractedSlug));
        const querySnapshot = await getDocs(pageQuery);

        //console.log("Sorgu sonucu döküman sayısı:", querySnapshot.size);

        if (!querySnapshot.empty) {
          const pageDoc = querySnapshot.docs[0];
          const data = pageDoc.data();
          //console.log("Bulunan sayfa verileri:", data);
          setPageContent(data);
          setError(null);
        } else {
          //console.log("Eşleşen sayfa bulunamadı");
          setError(`"${extractedSlug}" başlıklı sayfa bulunamadı`);
        }
      } catch (error) {
        console.error("Veritabanı sorgu hatası:", error);
        setError("Sayfa yüklenirken bir hata oluştu. Lütfen tekrar deneyin.");
      } finally {
        setLoading(false);
      }
    };

    fetchPageContent();
  }, [extractedSlug]);

  if (loading) {
    return (
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
      >
        <CircularProgress />
      </MDBox>
    );
  }

  if (error) {
    return (
      <MDBox px={1} my={1} mx={2}>
        <Typography color="error" variant="h6" gutterBottom>
          {error}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {error === "Sayfa URL'si eksik"
            ? "URL formatı geçersiz. Lütfen kontrol ediniz."
            : "Sayfa bulunamadı. Lütfen URL'yi kontrol ediniz."}
        </Typography>
        <Typography
          variant="body2"
          color="primary"
          sx={{ cursor: "pointer", mt: 2 }}
          onClick={() => navigate("/ana-sayfa")}
        >
          Ana Sayfaya Dön
        </Typography>
      </MDBox>
    );
  }

  return (
    <MDBox px={1} my={1} mx={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {pageContent && (
            <>
              <Typography variant="h2" component="h1" gutterBottom>
                {pageContent.title}
              </Typography>
              <div
                dangerouslySetInnerHTML={{ __html: pageContent.content }}
                style={{
                  overflow: "auto",
                }}
              />
            </>
          )}
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default StaticPage;
