import React, { useState, useEffect } from 'react';
import {
    Container,
    TextField,
    Paper,
    CircularProgress,
} from '@mui/material';
import { Send as SendIcon } from '@mui/icons-material';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { collection, addDoc } from 'firebase/firestore';
import { db } from 'lib/firebase';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Form doğrulama şeması
const validationSchema = yup.object({
    name: yup
        .string()
        .required('İsim alanı zorunludur')
        .min(2, 'İsim en az 2 karakter olmalıdır'),
    email: yup
        .string()
        .email('Geçerli bir e-posta adresi giriniz')
        .required('E-posta alanı zorunludur'),
    subject: yup
        .string()
        .required('Konu alanı zorunludur')
        .min(5, 'Konu en az 5 karakter olmalıdır'),
    message: yup
        .string()
        .required('Mesaj alanı zorunludur')
        .min(10, 'Mesaj en az 10 karakter olmalıdır')
});

const IletisimLayout = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [recaptchaLoaded, setRecaptchaLoaded] = useState(false);


    // reCAPTCHA'nın yüklenip yüklenmediğini kontrol et
    useEffect(() => {
        // Önce mevcut scripti kontrol et
        const existingScript = document.querySelector(`script[src*="recaptcha"]`);
        if (existingScript) {
            document.head.removeChild(existingScript);
        }

        // Yeni script ekle
        const script = document.createElement('script');
        script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
        script.async = true;
        script.defer = true;

        // Script yükleme başarılı olduğunda
        script.onload = () => {
            // reCAPTCHA'nın hazır olması için bekle
            window.grecaptcha?.ready(() => {
                //console.log('reCAPTCHA hazır');
                setRecaptchaLoaded(true);
            });
        };

        // Script yükleme başarısız olduğunda
        script.onerror = (error) => {
            console.error('reCAPTCHA script yükleme hatası:', error);
        };

        document.head.appendChild(script);

        return () => {
            // Cleanup
            const script = document.querySelector(`script[src*="recaptcha"]`);
            if (script) {
                document.head.removeChild(script);
            }
        };
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
        if (errors[name]) {
            setErrors(prev => ({
                ...prev,
                [name]: ''
            }));
        }
    };

    // Geliştirilmiş reCAPTCHA token alma fonksiyonu
    const executeRecaptcha = async () => {
        try {
            // reCAPTCHA'nın yüklendiğinden emin ol
            if (!window.grecaptcha) {
                throw new Error('reCAPTCHA yüklenmedi');
            }

            // reCAPTCHA'nın hazır olmasını bekle
            await new Promise((resolve) => {
                window.grecaptcha.ready(resolve);
            });

            const token = await window.grecaptcha.execute(
                process.env.REACT_APP_RECAPTCHA_SITE_KEY,
                { action: 'submit_contact_form' }
            );

            return token;
        } catch (error) {
            console.error('reCAPTCHA hatası:', error);
            throw new Error('Güvenlik doğrulaması başarısız oldu');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            // Form doğrulama
            await validationSchema.validate(formData, { abortEarly: false });

            if (!recaptchaLoaded) {
                throw new Error('reCAPTCHA henüz hazır değil. Lütfen birkaç saniye bekleyin.');
            }

            // reCAPTCHA token'ı al
            const recaptchaToken = await executeRecaptcha();

            // Firebase'e kaydet
            await addDoc(collection(db, 'contacts'), {
                ...formData,
                recaptchaToken,
                createdAt: new Date()
            });

            toast.success('Mesajınız başarıyla gönderildi!');

            // Formu temizle
            setFormData({
                name: '',
                email: '',
                subject: '',
                message: ''
            });

        } catch (error) {
            if (error.inner) {
                // Yup doğrulama hataları
                const newErrors = {};
                error.inner.forEach(err => {
                    newErrors[err.path] = err.message;
                });
                setErrors(newErrors);
            } else {
                toast.error(`Hata: ${error.message}`);
                console.error('Form gönderme hatası:', error);
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container maxWidth="md">
            <MDBox my={4}>
                <Paper elevation={3} sx={{ p: 4 }}>
                    <MDTypography variant="h4" component="h1" gutterBottom align="center">
                        İletişim
                    </MDTypography>

                    <MDTypography variant="body1" gutterBottom align="center" sx={{ mb: 4 }}>
                        Benimle iletişime geçmek için aşağıdaki formu doldurun.
                    </MDTypography>

                    <form onSubmit={handleSubmit}>
                        <MDBox display="flex" flexDirection="column" gap={3}>
                            <TextField
                                fullWidth
                                label="İsim"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                error={!!errors.name}
                                helperText={errors.name}
                                disabled={loading}
                                size="small"
                            />

                            <TextField
                                fullWidth
                                label="E-posta"
                                name="email"
                                type="email"
                                value={formData.email}
                                onChange={handleChange}
                                error={!!errors.email}
                                helperText={errors.email}
                                disabled={loading}
                                size="small"
                            />

                            <TextField
                                fullWidth
                                label="Konu"
                                name="subject"
                                value={formData.subject}
                                onChange={handleChange}
                                error={!!errors.subject}
                                helperText={errors.subject}
                                disabled={loading}
                                size="small"
                            />

                            <TextField
                                fullWidth
                                label="Mesaj"
                                name="message"
                                multiline
                                rows={4}
                                value={formData.message}
                                onChange={handleChange}
                                error={!!errors.message}
                                helperText={errors.message}
                                disabled={loading}
                                size="small"
                            />

                            <MDButton
                                type="submit"
                                variant="contained"
                                color="info"
                                size="large"
                                disabled={loading}
                                endIcon={loading ? <CircularProgress size={20} /> : <SendIcon />}
                            >
                                {loading ? 'Gönderiliyor...' : 'Gönder'}
                            </MDButton>
                        </MDBox>
                    </form>
                </Paper>
            </MDBox>
        </Container>
    );
};

export default IletisimLayout;