import Grid from "@mui/material/Grid";
import Carousel from "react-material-ui-carousel";
import MDBox from "components/MDBox";
import PropTypes from "prop-types";
import MDLoadingSkeleton from "components/MDLoadingSkeleton";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import MDTypography from "components/MDTypography";
import { useNavigate } from "react-router-dom";
import MDAlert from "components/MDAlert";
import { useGetPostsQuery } from "services/postApi";
import { useGetFeaturedPostsQuery } from "services/postApi";
import { useState } from 'react';
import MDPagination from "components/MDPagination"; // MDPagination bileşenini import edin

/**
 * Ana sayfa layout'unu oluşturur.
 *  - Giriş yapmamışsa loading skeleton'lar gösterir.
 *  - Giriş yapmışsa:
 *    - Öne çıkan post'ları bir carusel'da gösterir.
 *    - Son post'ları 12'si bir sayfada gösterir.
 *    - Sayfalama için pagination bileşenini gösterir.
 *  - Footer bileseninde şirket bilgisi ve link'ler bulunur.
 * @param {{ transparent: boolean, light: boolean }} props
 * @returns {ReactElement} Ana sayfa layout'u.
 */
function AnaSayfaLayout({ transparent, light }) {
  const [currentPage, setCurrentPage] = useState(1);
  const POSTS_PER_PAGE = 12; // Her sayfada gösterilecek post sayısı

  const {
    data: posts = [],
    isLoading: postsLoading,
    error: postsError,
  } = useGetPostsQuery();

  const {
    data: featuredPosts = [],
    isLoading: featuredLoading,
    error: featuredError,
  } = useGetFeaturedPostsQuery();

  const navigate = useNavigate();

  if (postsLoading || featuredLoading) {
    return (
      <MDBox px={{ xs: 1, sm: transparent ? 0 : 1 }} my={1} mx={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MDBox height="400px">
              <MDLoadingSkeleton />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    );
  }

  const error = postsError || featuredError;
  if (error) {
    return (
      <MDBox px={{ xs: 1, sm: transparent ? 0 : 1 }} my={1} mx={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MDBox height="400px">
              <MDAlert color="error">
                {error.message || "Bir hata oluştu"}
              </MDAlert>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    );
  }

  const renderCarousel = () => {
    if (!featuredPosts || featuredPosts.length === 0) {
      return null;
    }

    return (
      <MDBox mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MDBox sx={{ borderRadius: "15px", overflow: "hidden" }}>
              <Carousel
                animation="slide"
                autoPlay={true}
                indicators={true}
                navButtonsAlwaysVisible={true}
                cycleNavigation={true}
                interval={5000}
              >
                {featuredPosts.map((post) => (
                  <MDBox
                    key={post.id}
                    sx={{
                      cursor: "pointer",
                      position: "relative",
                      height: { xs: "350px", sm: "450px", md: "550px" },
                      width: "100%",
                      borderRadius: "lg",
                      overflow: "hidden",
                    }}
                    onClick={() => navigate(`/post/${post.slug}`)}
                  >
                    <img
                      src={post.featuredImageUrl}
                      alt={post.title}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                    <MDBox
                      sx={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        right: 0,
                        padding: 2,
                        background: "rgba(0,0,0,0.5)",
                        color: "white",
                      }}
                    >
                      <MDTypography variant="h5" color="white">
                        {post.title}
                      </MDTypography>
                      <MDTypography variant="body2" color="white">
                        {post.excerpt}
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                ))}
              </Carousel>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    );
  };

  const renderPosts = () => {
    if (!posts) return null;

    // Sayfalama için post'ları böl
    const indexOfLastPost = currentPage * POSTS_PER_PAGE;
    const indexOfFirstPost = indexOfLastPost - POSTS_PER_PAGE;
    const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

    return currentPosts.map((post) => (
      <Grid item key={post.id} xs={12} md={6} lg={4} xl={4} xxxl={3} mb={3}>
        <DefaultProjectCard
          image={
            post.featuredImageUrl ||
            "https://images.unsplash.com/photo-1497294815431-9365093b7371?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1950&q=80"
          }
          label={post.categoryName || "Genel"}
          title={post.title}
          description={post.excerpt}
          action={{
            route: `/post/${post.slug}`,
            label: "detay",
            color: "info",
            type: "internal",
          }}
          authors={[
            {
              image: post.authorAvatar,
              name: post.authorName || "Anonim",
            },
          ]}
        />
      </Grid>
    ));
  };

  const renderPagination = () => {
    if (!posts) return null;

    const totalPages = Math.ceil(posts.length / POSTS_PER_PAGE);

    return (
      <MDBox mt={4} display="flex" justifyContent="center">
        <MDPagination>
          {/* İlk sayfa butonu */}
          <MDPagination item
            onClick={() => setCurrentPage(1)}
            disabled={currentPage === 1}>
            <span>&laquo;</span>
          </MDPagination>

          {/* Önceki sayfa butonu */}
          <MDPagination item
            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}>
            <span>&lsaquo;</span>
          </MDPagination>

          {/* Sayfa numaraları */}
          {[...Array(totalPages)].map((_, index) => (
            <MDPagination item
              key={index + 1}
              onClick={() => setCurrentPage(index + 1)}
              active={currentPage === index + 1}>
              {index + 1}
            </MDPagination>
          ))}

          {/* Sonraki sayfa butonu */}
          <MDPagination item
            onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}>
            <span>&rsaquo;</span>
          </MDPagination>

          {/* Son sayfa butonu */}
          <MDPagination item
            onClick={() => setCurrentPage(totalPages)}
            disabled={currentPage === totalPages}>
            <span>&raquo;</span>
          </MDPagination>
        </MDPagination>
      </MDBox>
    );
  };

  return (
    <MDBox
      px={{
        xs: 1,
        sm: transparent ? 0 : 1,
        md: transparent ? 0 : 1,
        xl: transparent ? 0 : 1,
        lg: transparent ? 0 : 1,
      }}
      my={1}
      mx={2}
    >
      {renderCarousel()}
      <MDBox mb={3}>
        <Grid container spacing={2}>
          {renderPosts()}
        </Grid>
        {renderPagination()}
      </MDBox>
    </MDBox>
  );
}

AnaSayfaLayout.defaultProps = {
  transparent: false,
  light: false,
};

AnaSayfaLayout.propTypes = {
  transparent: PropTypes.bool,
  light: PropTypes.bool,
};

export default AnaSayfaLayout;