import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

// Redux Provider
import { store } from "./store/store"; // Az önce oluşturduğumuz store'u import ediyoruz

//  Context Provider
import { MaterialUIControllerProvider } from "context";
import AppWrapper from "AppWrapper";
import { Provider } from "react-redux";

const container = document.getElementById("app");
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <MaterialUIControllerProvider>
        <AppWrapper />
      </MaterialUIControllerProvider>
    </BrowserRouter>
  </Provider>
);
