// hooks/useFirestoreRoutes.js
import { useState, useEffect, useMemo } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "lib/firebase";
import Icon from "@mui/material/Icon";

// Dinamik route bileşenlerini import ediyoruz
import AnaSayfaLayout from "layouts/AnaSayfaLayout";
import HakkimdaLayout from "layouts/HakkimdaLayout";
import GirisYapLayout from "layouts/authenticationLayouts/girisyapLayout";
import KaydolLayout from "layouts/authenticationLayouts/kaydolLayout";
import PostDetailLayout from "layouts/PostDetailLayout";
import PostsCategoryLayout from "layouts/PostsCategoryLayout";
import StaticPage from "layouts/StaticPage";
import IletisimLayout from "layouts/IletisimLayout";

// Statik component mapping
const COMPONENT_MAP = {
  AnaSayfaLayout: AnaSayfaLayout,
  HakkimdaLayout: HakkimdaLayout,
  GirisYapLayout: GirisYapLayout,
  KaydolLayout: KaydolLayout,
  PostDetailLayout: PostDetailLayout,
  PostsCategoryLayout: PostsCategoryLayout,
  StaticPage: StaticPage,
  IletisimLayout: IletisimLayout,
};

/**
 * Converts a string to a URL-friendly string by replacing non-alphanumeric
 * characters with hyphens and converting Turkish characters to their Latin
 * equivalents.
 *
 * @param {string} str The string to convert.
 * @returns {string} The URL-friendly string.
 */

function urlFriendlyString(str) {
  // Türkçe karakterleri değiştirme mapping'i
  const turkishChars = {
    ı: "i",
    ğ: "g",
    ü: "u",
    ş: "s",
    ö: "o",
    ç: "c",
    İ: "i",
    Ğ: "g",
    Ü: "u",
    Ş: "s",
    Ö: "o",
    Ç: "c",
  };

  return (
    str
      // Tüm karakterleri küçük harfe çevir
      .toLowerCase()
      // Türkçe karakterleri değiştir
      .replace(/[ıİğĞüÜşŞöÖçÇ]/g, (char) => turkishChars[char])
      // Alfanümerik olmayan karakterleri tire ile değiştir
      .replace(/[^a-z0-9]+/g, "-")
      // Baştaki ve sondaki tireleri kaldır
      .replace(/^-+|-+$/g, "")
  );
}

/**
 * useFirestoreRoutes hook'u, Firestore'dan navbar menü verilerini alır ve
 * route'ları oluşturur.
 *
 * @returns {Object} `routes`, `loading` ve `error` properties'ini içeren
 * bir obje.
 *
 * @property {Array} routes Menü verilerinin oluşturduğu route'lar.
 * @property {boolean} loading Verilerin yükleniyor olup olmadığını gösterir.
 * @property {string|null} error Verilerin yüklenmesinde bir hata varsa hata
 * mesajını gösterir.
 */
export const useFirestoreRoutes = () => {
  const [menuData, setMenuData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    let isSubscribed = true;

    const fetchRoutes = async () => {
      try {
        const docRef = doc(db, "blog", "navbarMenu");
        const docSnap = await getDoc(docRef);

        if (!isSubscribed) return;

        if (docSnap.exists()) {
          setMenuData(
            docSnap.data().menuList.sort((a, b) => a.name.localeCompare(b.name))
          );
        } else {
          setError("Navbar menu not found");
        }
      } catch (err) {
        if (isSubscribed) {
          setError(err.message);
        }
      } finally {
        if (isSubscribed) {
          setLoading(false);
        }
      }
    };

    fetchRoutes();

    return () => {
      isSubscribed = false;
    };
  }, []);

  const routes = useMemo(() => {
    if (!menuData) return [];

    const menuRoutes = menuData.map((menu) => {
      const Component = COMPONENT_MAP[menu.component];
      return {
        ...menu,
        key: urlFriendlyString(menu.name),
        icon: <Icon fontSize="small">{menu.icon || "dashboard"}</Icon>,
        component: menu?.categoryId ? (
          <PostsCategoryLayout categoryId={menu.categoryId} />
        ) : (
          Component && <Component />
        ),
      };
    });

    // Post detay route'unu ekle
    const postDetailRoute = {
      route: "/post/:slug", // route property'sini kullanıyoruz çünkü diğer route'lar da bu property'yi kullanıyor
      name: "Post Detail",
      component: <PostDetailLayout />,
      key: "post-detail",
    };

    return [...menuRoutes, postDetailRoute];
  }, [menuData]);

  return { routes, loading, error };
};
