import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import CoverLayout from "layouts/authenticationLayouts/components/CoverLayout";
import { auth } from "../../../lib/firebase";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signOut,
} from "firebase/auth";
import bgImage from "assets/images/bg-sign-up-cover.jpeg";
import {
  doc,
  getFirestore,
  setDoc,
  serverTimestamp,
} from "firebase/firestore";
import { toast } from "react-toastify";

/**
 * Kaydol sayfasını oluşturur.
 *
 * @returns {React.ReactElement} Kaydol sayfası componenti.
 */
function KaydolLayout() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [dogumTarihi, setDogumTarihi] = useState("");
  const [error, setError] = useState("");
  const [agreement, setAgreement] = useState(false);
  const navigate = useNavigate();

  /**
   * Kaydol formunu gönderir.
   *
   * Formu gönderildiğinde:
   * - Kullanıcı oluşturulur
   * - E-posta doğrulama linki gönderilir
   * - Kullanıcının oturumu kapatılır
   * - Toast bildirimi gösterilir
   * - 3 saniye sonra giriş sayfasına yönlendirilir
   *
   * Form gönderilirken hata oluşursa, hata mesajı toast bildirimi olarak gösterilir
   * ve formun altındaki hata mesajı alanına yazılır.
   *
   * @param {React.FormEvent<HTMLFormElement>} e - Form event
   */
  const handleSignUp = async (e) => {
    e.preventDefault();

    if (!email || !password || !name || !surname || !dogumTarihi) {
      setError("Lütfen tüm alanları doldurun.");
      return;
    }

    if (!agreement) {
      setError("Lütfen kullanım koşullarını kabul edin.");
      return;
    }

    setError("");
    let user_id = null;

    try {
      // Kullanıcı oluştur
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      user_id = userCredential.user.uid;

      // E-posta doğrulama linki gönder
      await sendEmailVerification(userCredential.user, {
        url: window.location.origin + "/giris-yap",
        handleCodeInApp: true,
      });

      const db = getFirestore();
      const userDocRef = doc(db, "users", user_id);
      const userInfo = {
        username: email.split("@")[0],
        email: email,
        fullName: name + " " + surname,
        bio: "",
        avatarUrl: "",
        role: "user",
        isActive: true,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        birthday: dogumTarihi,
        emailVerified: false,
      };

      await setDoc(userDocRef, userInfo);

      // Önemli: Kullanıcının oturumunu kapat
      await signOut(auth);

      // Toast bildirimi göster
      toast.success(
        "Hesabınız başarıyla oluşturuldu! E-posta adresinize bir doğrulama bağlantısı gönderdik.",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );

      // setSuccess(true);

      // 3 saniye sonra giriş sayfasına yönlendir
      // setTimeout(() => {
      //   navigate("/auth/giris-yap");
      // }, 3000);
    } catch (err) {
      console.error("Hata:", err);
      toast.error(
        err.code === "auth/email-already-in-use"
          ? "Bu e-posta adresi zaten kullanılıyor."
          : "Hesap oluşturulurken bir hata oluştu.",
        {
          position: "top-right",
          autoClose: 5000,
        }
      );
      // if (err.code === "auth/email-already-in-use") {
      //   setError(
      //     "Bu e-posta adresi zaten kullanılıyor. Lütfen farklı bir e-posta adresi kullanın."
      //   );
      // } else if (err.code === "auth/invalid-email") {
      //   setError("Lütfen gecerli bir e-posta adresi girin.");
      // } else {
      //   setError(
      //     "Hesap oluşturulurken bir hata oluştu. Lütfen tekrar deneyin."
      //   );
      // }
    }
  };

  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-9}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Gkhnmr.com
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Kaydolmak için bilgileri doldurun
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={handleSignUp}>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="İsim"
                variant="standard"
                fullWidth
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Soyad"
                variant="standard"
                fullWidth
                value={surname}
                onChange={(e) => setSurname(e.target.value)}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="date"
                label="Doğum Tarihi"
                variant="standard"
                fullWidth
                value={dogumTarihi}
                onChange={(e) => setDogumTarihi(e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="E-Posta"
                variant="standard"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Şifre"
                variant="standard"
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Checkbox
                checked={agreement}
                onChange={(e) => setAgreement(e.target.checked)}
              />
              <MDTypography
                component="a"
                href="#"
                variant="button"
                fontWeight="bold"
                color="info"
                textGradient
              >
                Kullanım Koşullarını
              </MDTypography>
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp; kabul ediyorum.&nbsp;
              </MDTypography>
            </MDBox>
            {error && (
              <MDTypography
                variant="caption"
                color="error"
                sx={{ mt: 2, display: "block" }}
              >
                {error}
              </MDTypography>
            )}
            <MDButton
              variant="gradient"
              color="info"
              fullWidth
              type="submit"
              sx={{ mt: 2 }}
            >
              Kaydol
            </MDButton>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Zaten bir hesabın var mı?{" "}
                <MDTypography
                  component={Link}
                  to="/auth/giris-yap"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Giriş Yap
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default KaydolLayout;
