import { useState, useEffect, useCallback } from "react";
import { useParams, useLocation } from "react-router-dom";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";
import { db, appCheck } from "../../lib/firebase";
import MDBox from "components/MDBox";
import { Grid, Card, CardContent, Divider, styled } from "@mui/material";
import MDLoadingSkeleton from "components/MDLoadingSkeleton";
import MDErrorDisplay from "components/MDErrorDisplay";
import MDSocialShare from "components/MDSocialShare";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useMaterialUIController } from "context";
import CommentItem from "./components/CommentItem";

import { MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import { Clock, History } from "lucide-react";
import { useAccount } from "context/AccountContext";

import PropTypes from "prop-types";

import hljs from "highlight.js";
import "highlight.js/styles/atom-one-dark.css"; // veya tercih ettiğiniz tema
// highlight.js ve dil tanımlamaları
import bash from "highlight.js/lib/languages/bash";
import shell from "highlight.js/lib/languages/shell";
import xml from "highlight.js/lib/languages/xml";
import javascript from "highlight.js/lib/languages/javascript";
import css from "highlight.js/lib/languages/css";
import { getToken } from "firebase/app-check";
import { trackPostView } from "utils/tracking";
import { Helmet } from "react-helmet";

// Dilleri kaydet
hljs.registerLanguage("bash", bash);
hljs.registerLanguage("shell", shell);
hljs.registerLanguage("html", xml);
hljs.registerLanguage("xml", xml);
hljs.registerLanguage("javascript", javascript);
hljs.registerLanguage("js", javascript);
hljs.registerLanguage("css", css);

const generateRandomId = () => {
  return Math.random().toString(36).substring(2) + Date.now().toString(36);
};

// Toplam yanıt sayısını hesaplayan yardımcı fonksiyon
const getTotalReplies = (comment) => {
  let count = 0;
  if (comment.replies) {
    count += comment.replies.length;
    comment.replies.forEach((reply) => {
      count += getTotalReplies(reply);
    });
  }
  return count;
};

// Yorum sıralama fonksiyonu
const sortComments = (comments, sortOrder) => {
  const sortedComments = [...comments].sort((a, b) => {
    const dateA = a.createdAt?.toDate?.() || new Date(0);
    const dateB = b.createdAt?.toDate?.() || new Date(0);
    return sortOrder === "newest" ? dateB - dateA : dateA - dateB;
  });

  // Alt yanıtları da sırala
  return sortedComments.map((comment) => {
    if (comment.replies?.length) {
      return {
        ...comment,
        replies: sortComments(comment.replies, sortOrder),
      };
    }
    return comment;
  });
};

// Özel stil bileşenleri oluşturun (bileşen dışında)
const CodeContent = styled("div")(({ theme, darkMode }) => ({
  "& pre": {
    margin: "1rem 0",
    padding: "2.5rem 1rem 1rem", // Üst padding'i artırdık
    borderRadius: "8px",
    backgroundColor: darkMode ? "#1a1a1a" : "#f5f5f5",
    overflowX: "auto",
    position: "relative",
    fontSize: "0.9rem",
    lineHeight: "1.5",
    "&::before": {
      // Dil etiketi
      content: "attr(data-language)",
      position: "absolute",
      top: "0.5rem",
      left: "1rem",
      color: darkMode ? "#ffffff80" : "#00000080",
      fontSize: "0.75rem",
      textTransform: "uppercase",
      fontFamily: "system-ui, -apple-system, sans-serif",
    },
  },
  "& code": {
    fontFamily: "'Fira Code', 'Consolas', monospace",
    fontSize: "0.9rem",
    wordSpacing: "normal",
    wordWrap: "normal",
    lineHeight: "1.5",
    tabSize: "4",
    hyphens: "none",
    maxWidth: "100%",
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
  },
  // Tema renkleri
  "& .hljs": {
    color: darkMode ? "#abb2bf" : "#383a42",
    background: "transparent",
  },
  "& .hljs-comment": {
    color: darkMode ? "#5c6370" : "#a0a1a7",
    fontStyle: "italic",
  },
  "& .hljs-keyword": {
    color: darkMode ? "#c678dd" : "#a626a4",
  },
  "& .hljs-string": {
    color: darkMode ? "#98c379" : "#50a14f",
  },
  "& .hljs-number": {
    color: darkMode ? "#d19a66" : "#986801",
  },
  "& .hljs-function": {
    color: darkMode ? "#61afef" : "#4078f2",
  },
  "& .hljs-title": {
    color: darkMode ? "#61afef" : "#4078f2",
  },
  "& .hljs-params": {
    color: darkMode ? "#abb2bf" : "#383a42",
  },
  "& .hljs-built_in": {
    color: darkMode ? "#e6c07b" : "#c18401",
  },
}));

/**
 * PostDetailLayout component
 *
 * Bu bileşen, bir post'un detaylarını, yorumlarını ve paylaşım butonlarını
 * gösterir. Ayrıca, kullanıcı yorum ekleyebilir.
 *
 * @param {Object} props properties
 * @param {string} props.slug post'un slug'u
 * @returns {ReactElement} rendered component
 */
function PostDetailLayout() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { slug } = useParams();
  const location = useLocation();
  const [post, setPost] = useState(null);
  const [author, setAuthor] = useState(null);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [loading, setLoading] = useState(true);
  const [commenting, setCommenting] = useState(false);
  const [error, setError] = useState(null);
  const [sortOrder, setSortOrder] = useState("newest"); // "newest" veya "oldest"
  const { userAccountInfo } = useAccount();

  const fullUrl = `${window.location.origin}${location.pathname}`;

  // Kod bloklarını highlight eden fonksiyon
  const highlightCode = useCallback(() => {
    // Tüm kod bloklarını seç
    const codeBlocks = document.querySelectorAll("pre code");

    if (!codeBlocks.length) return;

    // Her bir kod bloğunu işle
    codeBlocks.forEach((block) => {
      // Eğer zaten highlight edilmişse ve butonu varsa atla
      if (block.parentElement.querySelector(".code-header")) return;

      // Highlight.js'i uygula
      hljs.highlightElement(block);

      const pre = block.parentElement;
      if (!pre || pre.tagName !== "PRE") return;

      // Dil bilgisini al
      const language =
        block.className
          .split(" ")
          .find((cls) => cls.startsWith("language-"))
          ?.replace("language-", "") || "text";

      // Pre elementini ayarla
      pre.style.position = "relative";
      pre.style.paddingTop = "2.5rem";
      pre.setAttribute("data-language", language);

      // Kopyalama butonu ekle
      const header = document.createElement("div");
      header.className = "code-header";
      header.style.cssText = `
        position: absolute;
        top: 0.5rem;
        right: 1rem;
        z-index: 1;
      `;

      const copyButton = document.createElement("button");
      copyButton.textContent = "Kopyala";
      copyButton.className = "copy-button";
      copyButton.style.cssText = `
        background: ${darkMode ? "#333" : "#e0e0e0"};
        color: ${darkMode ? "#fff" : "#666"};
        border: none;
        padding: 0.2rem 0.8rem;
        border-radius: 4px;
        cursor: pointer;
        font-size: 0.8rem;
        transition: all 0.2s ease;
      `;

      copyButton.onclick = async () => {
        try {
          await navigator.clipboard.writeText(block.textContent);
          copyButton.textContent = "Kopyalandı!";
          copyButton.style.backgroundColor = "#4caf50";
          setTimeout(() => {
            copyButton.textContent = "Kopyala";
            copyButton.style.backgroundColor = darkMode ? "#333" : "#e0e0e0";
          }, 2000);
        } catch (err) {
          console.error("Kopyalama hatası:", err);
        }
      };

      header.appendChild(copyButton);
      pre.appendChild(header);
    });
  }, [darkMode]);

  // Yorumları hiyerarşik yapıya dönüştür
  const organizeComments = (commentsArray) => {
    const commentMap = new Map();
    const topLevelComments = [];

    // Önce tüm yorumları map'e ekle
    commentsArray.forEach((comment) => {
      commentMap.set(comment.id, { ...comment, replies: [] });
    });

    // Yanıtları düzenle
    commentsArray.forEach((comment) => {
      if (comment.parentId) {
        const parentComment = commentMap.get(comment.parentId);
        if (parentComment) {
          if (comment.isApproved) {
            parentComment.replies.push(commentMap.get(comment.id));
          }
        }
      } else {
        if (comment.isApproved) {
          topLevelComments.push(commentMap.get(comment.id));
        }
      }
    });

    return topLevelComments;
  };

  // Yorumları getir
  const fetchComments = useCallback(
    async (postId) => {
      try {
        const commentsRef = collection(db, `posts/${postId}/comments`);
        const q = query(commentsRef); // orderBy'ı kaldırdık çünkü manuel sıralama yapacağız
        const querySnapshot = await getDocs(q);

        const commentsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Yorumları organize et ve sırala
        const organizedComments = organizeComments(commentsData);
        const sortedComments = sortComments(organizedComments, sortOrder);
        setComments(sortedComments);
      } catch (err) {
        console.error("Yorumları getirme hatası:", err);
      }
    },
    [sortOrder]
  );

  useEffect(() => {
    const handleError = (error) => {
      console.error("Highlighting error:", error);
      setError("İçerik görüntülenirken bir hata oluştu");
    };

    window.addEventListener("error", handleError);
    return () => window.removeEventListener("error", handleError);
  }, []);

  useEffect(() => {
    if (!loading && post?.content) {
      const timer = setTimeout(highlightCode, 100);
      return () => clearTimeout(timer);
    }
  }, [loading, post, highlightCode]);

  // Sıralama değiştiğinde yorumları yeniden sırala
  useEffect(() => {
    if (comments.length > 0) {
      setComments(sortComments(comments, sortOrder));
    }
  }, [sortOrder]);

  // Yoruma yanıt verme
  const handleReply = useCallback(
    async (parentCommentId, content) => {
      if (!content.trim()) return;

      try {
        const commentsRef = collection(db, `posts/${post.id}/comments`);
        await addDoc(commentsRef, {
          content,
          parentId: parentCommentId,
          authorId: userAccountInfo?.uid || generateRandomId(),
          authorName: userAccountInfo?.fullName || "Ziyaretçi", // Oturum açmış kullanıcının adı
          authorAvatarUrl: userAccountInfo?.avatarUrl || "", // Oturum açmış kullanıcının avatar URL'i
          isApproved:
            userAccountInfo?.role === "admin" ||
            userAccountInfo?.role === "editor"
              ? true
              : false,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        });

        // Yorumları yeniden yükle
        await fetchComments(post.id);
      } catch (err) {
        console.error("Yanıt ekleme hatası:", err);
        setError("Yanıt eklenirken bir hata oluştu");
      }
    },
    [post?.id]
  );

  // Post verilerini getir
  useEffect(() => {
    const fetchPostAndAuthor = async () => {
      try {
        setLoading(true);

        // Önbellek kontrolü
        const viewKey = `post_view_${slug}`;
        const lastView = localStorage.getItem(viewKey);
        const now = Date.now();
        const shouldTrackView =
          !lastView || now - parseInt(lastView) > 24 * 60 * 60 * 1000;

        // Önce slug'a göre post'u getir
        const postsRef = collection(db, "posts");
        const q = query(
          postsRef,
          where("slug", "==", slug),
          where("status", "==", "published")
        );
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
          setError("Post bulunamadı");
          return;
        }

        const postDoc = querySnapshot.docs[0];
        const postData = { id: postDoc.id, ...postDoc.data() };

        // Post başarıyla bulunduktan sonra görüntüleme kaydı oluştur
        if (shouldTrackView) {
          await trackPostView(postDoc.id, userAccountInfo?.uid);
          localStorage.setItem(viewKey, now.toString());
        }

        // Yazar bilgilerini getir
        if (postData.authorId) {
          try {
            const authorRef = doc(db, "users", postData.authorId);
            const authorSnapshot = await getDoc(authorRef);

            if (authorSnapshot.exists()) {
              const authorData = authorSnapshot.data();
              setAuthor({
                id: authorSnapshot.id,
                ...authorData,
                avatarUrl: authorData.avatarUrl || "/default-avatar.png",
                fullName: authorData.fullName || "Anonim Yazar",
              });
            }
          } catch (authorError) {
            console.warn("Yazar bilgileri alınamadı:", authorError);
          }
        }

        setPost(postData);
        await fetchComments(postDoc.id);
      } catch (err) {
        console.error("Veri getirme hatası:", err);
        setError("İçerik yüklenirken bir hata oluştu");
      } finally {
        setLoading(false);
      }
    };

    if (slug) {
      fetchPostAndAuthor();
    }
  }, [slug, fetchComments]); // fetchComments'i dependency array'e ekleyin

  useEffect(() => {
    if (post?.content) {
      highlightCode();
    }
  }, [darkMode]); // Sadece darkMode değiştiğinde çalışsın

  // MutationObserver ile içerik değişikliklerini izle
  useEffect(() => {
    if (!post?.content) return;

    // MutationObserver oluştur
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.addedNodes.length > 0) {
          highlightCode();
        }
      });
    });

    // İçerik container'ını izle
    const contentContainer = document.querySelector(".code-content");
    if (contentContainer) {
      observer.observe(contentContainer, {
        childList: true,
        subtree: true,
        characterData: true, // İçerik değişikliklerini de izle
      });
    }

    // Sayfa yüklendiğinde highlight'ı tekrar dene
    const timer = setTimeout(highlightCode, 500);

    return () => {
      observer.disconnect();
      clearTimeout(timer);
      // Eski kod bloklarının temizlenmesi
      const codeBlocks = document.querySelectorAll("pre code");
      codeBlocks.forEach((block) => {
        const header = block.parentElement?.querySelector(".code-header");
        header?.remove();
      });
    };
  }, [post, highlightCode]);

  // Yeni yorum ekleme
  const handleAddComment = useCallback(async () => {
    if (!newComment.trim()) return;

    setCommenting(true);
    try {
      const token = await getToken(appCheck);

      // Token kontrolü ekleyin
      if (!token || !token.token) {
        throw new Error("Güvenlik doğrulaması başarısız oldu");
      }

      const commentsRef = collection(db, `posts/${post.id}/comments`);
      // Not: Gerçek uygulamada bu değerler oturum açmış kullanıcıdan alınmalı
      await addDoc(commentsRef, {
        content: newComment,
        authorId: userAccountInfo?.uid || generateRandomId(),
        authorName: userAccountInfo?.fullName || "Ziyaretçi",
        authorAvatarUrl: userAccountInfo?.avatarUrl || "",
        isApproved:
          userAccountInfo?.role === "admin" ||
          userAccountInfo?.role === "editor"
            ? true
            : false, // Varsayılan onay durumu
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      });

      // Yorumları yeniden yükle
      await fetchComments(post.id);
      setNewComment("");
    } catch (err) {
      console.error("Yorum ekleme hatası: Token alınamadı!", err);
      setError("Yorum eklenirken bir hata oluştu");
    } finally {
      setCommenting(false);
    }
  }, [post?.id, newComment]);

  // Tüm yorumların toplam sayısını hesapla (alt yorumlar dahil)
  const getTotalCommentCount = (comments) => {
    let total = comments.length;
    comments.forEach((comment) => {
      total += getTotalReplies(comment);
    });
    return total;
  };

  if (loading) {
    return (
      <MDBox p={2}>
        <MDLoadingSkeleton />
      </MDBox>
    );
  }

  if (error) {
    return (
      <MDBox p={2}>
        <MDErrorDisplay message={error} />
      </MDBox>
    );
  }

  if (!post) {
    return (
      <MDBox p={2}>
        <MDErrorDisplay message="Post bulunamadı" />
      </MDBox>
    );
  }

  return (
    <>
      <Helmet>
        {/* Temel Meta Etiketleri */}
        <title>{post.title}</title>
        <meta name="description" content={post.excerpt} />

        {/* Open Graph Meta Etiketleri */}
        <meta property="og:title" content={post.title} />
        <meta property="og:description" content={post.excerpt} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={fullUrl} />
        {post.featuredImageUrl && (
          <meta property="og:image" content={post.featuredImageUrl} />
        )}

        {/* Article Specific Meta Tags */}
        <meta
          property="article:published_time"
          content={post.publishedAt?.toDate?.().toISOString()}
        />
        {author && <meta property="article:author" content={author.fullName} />}

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={post.title} />
        <meta name="twitter:description" content={post.excerpt} />
        {post.featuredImageUrl && (
          <meta name="twitter:image" content={post.featuredImageUrl} />
        )}
      </Helmet>
      <MDBox px={1} my={1} mx={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              {post.featuredImageUrl && (
                <MDBox
                  component="img"
                  src={post.featuredImageUrl}
                  alt={post.title}
                  sx={{
                    width: "100%",
                    height: "400px",
                    objectFit: "cover",
                    borderRadius: "5px 5px 0 0",
                  }}
                />
              )}
              <CardContent>
                <MDTypography variant="h4" component="h1" gutterBottom>
                  {post.title}
                </MDTypography>
                {/* Yazar Bilgisi */}
                <MDBox
                  display="flex"
                  alignItems="center"
                  mb={2}
                  sx={{
                    borderRadius: "8px",
                    backgroundColor: darkMode
                      ? "rgba(0, 0, 0, 0.2)"
                      : "#f5f5f5",
                    p: 1,
                  }}
                >
                  <MDAvatar
                    src={author?.avatarUrl}
                    alt={author?.fullName || post.authorName}
                    sx={{ width: 40, height: 40, mr: 2 }}
                  />
                  <MDBox display="flex" alignItems="center">
                    <MDTypography variant="subtitle2" fontWeight="medium">
                      Yazar: {author?.fullName || post.authorName || "Anonim"}
                    </MDTypography>

                    {author?.role && (
                      <MDTypography
                        variant="caption"
                        sx={{
                          textTransform: "capitalize",
                          color: "primary",
                          marginLeft: 0.5,
                          marginTop: 0.2,
                        }}
                      >
                        ({author.role})
                      </MDTypography>
                    )}
                  </MDBox>
                </MDBox>
                <MDTypography variant="subtitle2" gutterBottom>
                  {post.publishedAt?.toDate
                    ? new Date(post.publishedAt.toDate()).toLocaleDateString(
                        "tr-TR"
                      )
                    : post.createdAt?.toDate
                    ? new Date(post.createdAt.toDate()).toLocaleDateString(
                        "tr-TR"
                      )
                    : "Tarih belirtilmemiş"}
                </MDTypography>
                {/* Paylaşım Butonları */}
                <MDBox display="flex" alignItems="center" my={1}>
                  <Divider sx={{ my: 2 }} />
                  <MDTypography variant="subtitle2" pt={0.8} gutterBottom>
                    Paylaş:
                  </MDTypography>
                  <MDSocialShare url={fullUrl} title={post.title} />
                  <Divider sx={{ my: 2 }} />
                </MDBox>

                {/* İçerik render eden kısmı güncelleyin */}
                <CodeContent className="code-content" darkMode={darkMode}>
                  <MDTypography
                    variant="body1"
                    component="div"
                    sx={{
                      "& img": {
                        maxWidth: "100%",
                        height: "auto",
                        borderRadius: "8px",
                      },
                      "& a": {
                        color: darkMode ? "#8ab4f8" : "#1a73e8",
                        textDecoration: "none",
                        "&:hover": {
                          textDecoration: "underline",
                        },
                      },
                    }}
                    dangerouslySetInnerHTML={{ __html: post?.content || "" }}
                  />
                </CodeContent>

                {/* Alt kısımda tekrar paylaşım butonları */}
                <MDBox display="flex" alignItems="center" mx={1} mt={2}>
                  <Divider sx={{ my: 2 }} />
                  <MDTypography variant="subtitle2" pt={0.8} gutterBottom>
                    Paylaş:
                  </MDTypography>
                  <MDSocialShare url={fullUrl} title={post.title} />
                  <Divider sx={{ my: 2 }} />
                </MDBox>
              </CardContent>

              {/* Yorumlar Bölümü */}
              <CardContent>
                <MDBox display="flex" alignItems="center" gap={2}>
                  <MDTypography variant="h5">Yorumlar</MDTypography>
                  <MDTypography variant="body2" color="text">
                    ({comments.length} yorum, {getTotalCommentCount(comments)}{" "}
                    toplam yanıt)
                  </MDTypography>
                </MDBox>

                {/* Yorum Ekleme Formu */}
                <MDBox mb={3}>
                  <MDInput
                    fullWidth
                    multiline
                    rows={3}
                    variant="outlined"
                    placeholder="Yorumunuzu yazın..."
                    value={newComment}
                    onChange={(e) => setNewComment(e.target.value)}
                    sx={{ mb: 1 }}
                  />
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={handleAddComment}
                    disabled={commenting || !newComment.trim()}
                  >
                    {commenting ? "Gönderiliyor..." : "Yorum Yap"}
                  </MDButton>
                </MDBox>

                {/* Sıralama Seçenekleri */}
                <MDBox display="flex" justifyContent="end">
                  <FormControl
                    size="small"
                    sx={{ minWidth: 200, marginBottom: 2 }}
                  >
                    <InputLabel>Yorumları Sırala</InputLabel>
                    <Select
                      value={sortOrder}
                      onChange={(e) => setSortOrder(e.target.value)}
                      label="Yorumları Sırala"
                      sx={{
                        height: "36px",
                        "& .MuiSelect-select": {
                          // Select içeriğinin padding'ini ayarla
                          paddingTop: "5px",
                          paddingBottom: "5px",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                        },
                      }}
                    >
                      <MenuItem value="newest">
                        <MDBox display="flex" alignItems="center" gap={1}>
                          <Clock size={16} />
                          <MDTypography variant="button">En Yeni</MDTypography>
                        </MDBox>
                      </MenuItem>
                      <MenuItem value="oldest">
                        <MDBox display="flex" alignItems="center" gap={1}>
                          <History size={16} />
                          <MDTypography variant="button">En Eski</MDTypography>
                        </MDBox>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </MDBox>

                {/* Yorumlar Listesi */}
                <MDBox>
                  {comments.map((comment) => (
                    <CommentItem
                      key={comment.id}
                      comment={comment}
                      onReply={handleReply}
                      darkMode={darkMode}
                    />
                  ))}

                  {/* Yorum yoksa mesajı */}
                  {comments.length === 0 && (
                    <MDBox
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      gap={2}
                      py={4}
                    >
                      <MDTypography variant="body1" textAlign="center">
                        Henüz yorum yapılmamış.
                      </MDTypography>
                      <MDTypography
                        variant="caption"
                        color="text"
                        textAlign="center"
                      >
                        İlk yorumu siz yapın ve tartışmayı başlatın!
                      </MDTypography>
                    </MDBox>
                  )}
                </MDBox>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}

PostDetailLayout.propTypes = {
  post: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    authorId: PropTypes.string,
    // ... diğer prop tanımlamaları
  }),
};

export default PostDetailLayout;
