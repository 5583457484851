// hooks/useThemeSettings.js
import { useState, useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "lib/firebase"; // Firebase yapılandırma dosyanızın yolu

/**
 * Fetches the theme settings from the "template" document in the "blog" collection.
 *
 * @returns {Object} An object with the theme settings, a boolean indicating if the
 * data is still loading, and an error message if there's an error.
 */
export const useThemeSettings = () => {
  const [themeSettings, setThemeSettings] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchThemeSettings = async () => {
      try {
        const templateDoc = doc(db, "blog", "template");
        const docSnap = await getDoc(templateDoc);

        if (docSnap.exists()) {
          const { startSettings } = docSnap.data();
          setThemeSettings(startSettings);
        } else {
          setError("Template document not found");
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchThemeSettings();
  }, []);

  return { themeSettings, loading, error };
};
