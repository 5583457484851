// src/services/postApi.js
import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";
import { db } from "lib/firebase";
import {
  collection,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  orderBy,
  serverTimestamp,
  limit,
} from "firebase/firestore";

// Slug oluşturma fonksiyonunun hala erişilebilir olduğunu varsayıyoruz
import { generateSlug, convertFirestoreTimestamps } from "utils";

// Post verilerini hazırlayan yardımcı fonksiyon
async function preparePostData(postDoc) {
  const postData = postDoc.data();
  const authorRef = doc(db, "users", postData.authorId);

  try {
    const authorSnapshot = await getDoc(authorRef);
    const authorData = authorSnapshot.data();

    // Tüm verileri dönüştürüyoruz
    return convertFirestoreTimestamps({
      id: postDoc.id,
      ...postData,
      author: authorData
        ? {
            ...authorData,
            avatarUrl:
              authorData.avatarUrl ||
              "https://images.unsplash.com/photo-1497294815431-9365093b7331?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1950&q=80",
          }
        : null,
    });
  } catch (error) {
    console.warn(`Author data fetch failed for post ${postDoc.id}:`, error);
    // Yazar verisi alınamazsa bile postu döndürüyoruz
    return convertFirestoreTimestamps({
      id: postDoc.id,
      ...postData,
      author: null,
    });
  }
}

export const postApi = createApi({
  reducerPath: "postApi",
  baseQuery: fakeBaseQuery(),
  tagTypes: ["Posts", "FeaturedPosts"],
/**
 * Defines API endpoints for managing blog posts.
 *
 * - `getPosts`: Fetches all published posts ordered by creation date.
 * - `getPostBySlug`: Fetches a single post using its slug.
 * - `getFeaturedPosts`: Fetches featured posts limited to four.
 * - `createPost`: Creates a new post and returns its ID.
 * - `updatePost`: Updates an existing post by ID.
 * - `deletePost`: Deletes a post by ID.
 *
 * Each endpoint provides or invalidates cache tags to optimize data fetching.
 */
  endpoints: (builder) => ({
    // Tüm postları getirme
    getPosts: builder.query({
      async queryFn() {
        try {
          const q = query(
            collection(db, "posts"),
            where("status", "==", "published"),
            orderBy("createdAt", "desc")
          );

          const snapshot = await getDocs(q);
          const postsPromises = snapshot.docs.map(preparePostData);
          const posts = await Promise.all(postsPromises);

          return { data: posts };
        } catch (error) {
          console.error("Posts fetch error:", error);
          return { error: error.message };
        }
      },
      providesTags: ["Posts"],
    }),

    // Tek post getirme
    getPostBySlug: builder.query({
      async queryFn(slug) {
        try {
          const q = query(
            collection(db, "posts"),
            where("slug", "==", slug),
            limit(1)
          );

          const snapshot = await getDocs(q);
          if (snapshot.empty) return { data: null };

          const doc = snapshot.docs[0];
          return {
            data: {
              id: doc.id,
              ...doc.data(),
            },
          };
        } catch (error) {
          return { error: error.message };
        }
      },
      providesTags: (result, error, slug) => [{ type: "Posts", id: slug }],
    }),

    // Öne çıkan postları getirme
    getFeaturedPosts: builder.query({
      async queryFn() {
        try {
          const q = query(
            collection(db, "posts"),
            where("status", "==", "published"),
            where("isFeatured", "==", true), // Önemli: isFeatured kontrolünü sorguya taşıyalım
            orderBy("publishedAt", "desc"),
            limit(4) // Direkt olarak 4 post alalım
          );

          const snapshot = await getDocs(q);
          const postsPromises = snapshot.docs.map(async (postDoc) => {
            const postData = postDoc.data();
            const authorRef = doc(db, "users", postData.authorId);

            try {
              const authorSnapshot = await getDoc(authorRef);
              const authorData = authorSnapshot.data();

              return convertFirestoreTimestamps({
                id: postDoc.id,
                ...postData,
                authorName: authorData?.fullName || "Anonim",
                authorAvatar:
                  authorData?.avatarUrl || "https://via.placeholder.com/150",
                // Önemli: Carousel için gerekli alanların varlığından emin olalım
                featuredImageUrl:
                  postData.featuredImageUrl ||
                  "https://via.placeholder.com/1200x800",
                title: postData.title || "",
                excerpt: postData.excerpt || "",
                slug: postData.slug || "",
              });
            } catch (error) {
              console.warn(
                `Author fetch failed for post ${postDoc.id}:`,
                error
              );
              return null;
            }
          });

          const posts = (await Promise.all(postsPromises)).filter(Boolean);
          return { data: posts };
        } catch (error) {
          console.error("Featured posts fetch error:", error);
          return { error: error.message };
        }
      },
      providesTags: ["FeaturedPosts"],
    }),

    // Post oluşturma
    createPost: builder.mutation({
      async queryFn({ postData, userId }) {
        try {
          const post = {
            ...postData,
            authorId: userId,
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp(),
            slug: generateSlug(postData.title),
          };

          const docRef = await addDoc(collection(db, "posts"), post);
          return { data: { id: docRef.id } };
        } catch (error) {
          return { error: error.message };
        }
      },
      invalidatesTags: ["Posts", "FeaturedPosts"],
    }),

    // Post güncelleme
    updatePost: builder.mutation({
      async queryFn({ postId, postData }) {
        try {
          const postRef = doc(db, "posts", postId);
          await updateDoc(postRef, {
            ...postData,
            updatedAt: serverTimestamp(),
          });
          return { data: null };
        } catch (error) {
          return { error: error.message };
        }
      },
      invalidatesTags: (result, error, { postId }) => [
        "Posts",
        "FeaturedPosts",
        { type: "Posts", id: postId },
      ],
    }),

    // Post silme
    deletePost: builder.mutation({
      async queryFn(postId) {
        try {
          const postRef = doc(db, "posts", postId);
          await deleteDoc(postRef);
          return { data: null };
        } catch (error) {
          return { error: error.message };
        }
      },
      invalidatesTags: ["Posts", "FeaturedPosts"],
    }),
  }),
});

// Export hooks
export const {
  useGetPostsQuery,
  useGetPostBySlugQuery,
  useGetFeaturedPostsQuery,
  useCreatePostMutation,
  useUpdatePostMutation,
  useDeletePostMutation,
} = postApi;
