// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Menu from "@mui/material/Menu";

//  components
import MDBox from "components/MDBox";

// react-router components
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

//  example components
import DefaultNavbarLink from "examples/Navbars/DefaultNavbar/DefaultNavbarLink";
import { useAuth } from "AuthContext";
import useSessionManager from "hooks/useSessionManager";

function DefaultNavbarMobile({ open, close, menuItems, user }) {
  const { width } = open && open.getBoundingClientRect();
  //const { logout } = useAuth();
  const { logout } = useSessionManager();
  const navigate = useNavigate(); // useNavigate hook'unu kullan
  const handleLogout = async () => {
    try {
      await logout();
      navigate("/ana-sayfa");
    } catch (error) {
      console.error("Logout hatası:", error);
    }
  };

  return (
    <Menu
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      anchorEl={open}
      open={Boolean(open)}
      onClose={close}
      MenuListProps={{ style: { width: `calc(${width}px - 4rem)` } }}
    >
      <MDBox px={0.5}>
        {menuItems.map((item, index) =>
          item.isNavBarItems ? (
            <DefaultNavbarLink
              key={index}
              icon={item.icon}
              name={item.name}
              route={item.route}
              close={close}
            />
          ) : null
        )}

        {user?.emailVerified ? (
          <>
            <DefaultNavbarLink
              icon="dashboard"
              name="Panel"
              route="/admin/dashboard"
              close={close}
            />
            <DefaultNavbarLink
              icon="logout"
              name="Çıkış Yap"
              route="/ana-sayfa"
              onClick={handleLogout}
              close={close}
            />
          </>
        ) : (
          <>
            <DefaultNavbarLink
              icon="account_circle"
              name="Kaydol"
              route="/auth/kaydol"
              close={close}
            />
            <DefaultNavbarLink
              icon="key"
              name="Giriş Yap"
              route="/auth/giris-yap"
              close={close}
            />
          </>
        )}
      </MDBox>
    </Menu>
  );
}

DefaultNavbarMobile.defaultProps = {
  close: false,
  user: false,
};

// Typechecking props for the DefaultNavbarMenu
DefaultNavbarMobile.propTypes = {
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
  close: PropTypes.oneOfType([PropTypes.func, PropTypes.bool, PropTypes.object])
    .isRequired,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      icon: PropTypes.string,
      route: PropTypes.string.isRequired,
    })
  ).isRequired,
  user: PropTypes.object.isRequired,
};

export default DefaultNavbarMobile;
