import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db } from "lib/firebase";

/**
 * Bir yazının görüntülenmesini kaydeder.
 *
 * Bu fonksiyon, aynı kullanıcının aynı yazıyı 24 saat
 * içinde tekrar görüntülemesini önlemek için kontrol
 * yapar. Eğer son 24 saat içinde görüntülenmemişse
 * , yeni bir kayıt ekler.
 *
 * @param {string} postId - Yazının ID'si
 * @param {string} [userId] - Kullanıcının ID'si (isteğe bağlı)
 * @returns {Promise<void>}
 */
export const trackPostView = async (postId, userId) => {
  try {
    // Aynı kullanıcının tekrar görüntülemesini önlemek için kontrol
    const viewKey = `post_view_${postId}`;
    const lastView = localStorage.getItem(viewKey);
    const now = Date.now();

    // Son 24 saat içinde görüntülenmemişse kaydet
    if (!lastView || now - parseInt(lastView) > 24 * 60 * 60 * 1000) {
      // Görüntülenme koleksiyonuna yeni kayıt ekle
      await addDoc(collection(db, `posts/${postId}/views`), {
        timestamp: serverTimestamp(),
        userId: userId || "anonymous", // veya giriş yapmış kullanıcının ID'si
        userAgent: navigator.userAgent,
        // İsterseniz ek bilgiler ekleyebilirsiniz
      });

      // Son görüntülenme zamanını kaydet
      localStorage.setItem(viewKey, now.toString());
    }
  } catch (error) {
    console.error("Görüntülenme kaydı hatası:", error);
  }
};
