import { useEffect, useRef } from "react";
import { getAuth, signOut } from "firebase/auth";
import {
  getDatabase,
  ref,
  set,
  get,
  onDisconnect,
  serverTimestamp,
  remove,
} from "firebase/database";
import { toast } from "react-toastify";
import { useAuth } from "AuthContext";

/**
 * Oturum yöneticisi
 *
 * Bu hook, kullanıcı oturumunu takip eder ve
 * belirlenen süre zarfında kullanıcı activity
 * olmadığında, oturumu kapatır.
 *
 * Manuel çıkış fonksiyonunu da sağlar.
 *
 * @param {number} inactivityTimeout - Oturum kapatma süresi (varsayılan 30 dakika)
 * @returns {{ logout: () => Promise<void> }}
 */
const useSessionManager = (inactivityTimeout = 30 * 60 * 1000) => {
  const { user } = useAuth();
  const auth = getAuth();
  const db = getDatabase();
  const lastActivityRef = useRef(Date.now());
  const lastUpdateRef = useRef(Date.now());
  const UPDATE_INTERVAL = 10000;

  // Manuel çıkış işlemi için yardımcı fonksiyon
  const handleManualLogout = async (uid) => {
    try {
      const userLastActiveRef = ref(db, `userLastActive/${uid}`);
      const userStatusRef = ref(db, `userStatus/${uid}`);
      const userDisconnectRef = ref(db, `userDisconnectTime/${uid}`);

      // Tüm session verilerini temizle
      await remove(userLastActiveRef);
      await remove(userStatusRef);
      await remove(userDisconnectRef);

      await signOut(auth);
    } catch (error) {
      console.error("Manuel çıkış hatası:", error);
      // Hata olsa bile çıkış yapmaya çalış
      await signOut(auth);
    }
  };

  useEffect(() => {
    if (!user) return;

    const userLastActiveRef = ref(db, `userLastActive/${user.uid}`);
    const userStatusRef = ref(db, `userStatus/${user.uid}`);
    const userDisconnectRef = ref(db, `userDisconnectTime/${user.uid}`);

    const setupDisconnectHandlers = () => {
      // Kullanıcı bağlantısı kesildiğinde
      onDisconnect(userStatusRef).set("offline");
      onDisconnect(userDisconnectRef).set(serverTimestamp());
    };

    const checkLastDisconnect = async () => {
      try {
        const disconnectSnapshot = await get(userDisconnectRef);
        const statusSnapshot = await get(userStatusRef);

        // Eğer status "manual_logout" ise veya disconnect kaydı yoksa
        // yeni bir oturum olarak değerlendir
        if (
          !disconnectSnapshot.exists() ||
          (statusSnapshot.exists() && statusSnapshot.val() === "manual_logout")
        ) {
          return true;
        }

        const lastDisconnectTime = disconnectSnapshot.val();
        const now = Date.now();

        const disconnectTimestamp = lastDisconnectTime || now;
        const timeSinceDisconnect = now - disconnectTimestamp;

        if (timeSinceDisconnect >= inactivityTimeout) {
          await handleManualLogout(user.uid);
          toast.info("Uzun süre uzak kaldınız. Lütfen tekrar giriş yapın.");
          return false;
        }
        return true;
      } catch (error) {
        console.error("Son bağlantı kesme kontrolü hatası:", error);
        return true;
      }
    };

    const checkLastActivity = async () => {
      try {
        // Önce son bağlantı kesme durumunu kontrol et
        const canProceed = await checkLastDisconnect();
        if (!canProceed) return;

        const snapshot = await get(userLastActiveRef);
        const now = Date.now();

        // Yeni giriş kontrolü
        let isNewLogin = true;
        try {
          if (user.metadata?.lastSignInTime) {
            const lastSignInTime = new Date(
              user.metadata.lastSignInTime
            ).getTime();
            isNewLogin = now - lastSignInTime < 60000;
          }
        } catch (error) {
          //console.log("Metadata kontrolü yapılamadı");
        }

        if (isNewLogin || !snapshot.exists()) {
          await set(userLastActiveRef, now);
          await set(userStatusRef, "online");
          lastActivityRef.current = now;
          lastUpdateRef.current = now;
          setupDisconnectHandlers();
          return;
        }

        const lastActivityTime = snapshot.val();
        const timeSinceLastActivity = now - lastActivityTime;

        if (timeSinceLastActivity >= inactivityTimeout) {
          await handleManualLogout(user.uid);
          toast.info("Oturum süreniz dolmuş.");
        } else {
          lastActivityRef.current = now;
          lastUpdateRef.current = now;
          await set(userLastActiveRef, now);
          await set(userStatusRef, "online");
          setupDisconnectHandlers();
        }
      } catch (error) {
        console.error("Son aktivite kontrolü hatası:", error);
      }
    };

    // Sayfa görünürlük değişikliğini izle
    const handleVisibilityChange = async () => {
      if (document.visibilityState === "visible") {
        await checkLastActivity();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    checkLastActivity();

    const events = [
      "mousedown",
      "mousemove",
      "keypress",
      "scroll",
      "touchstart",
    ];
    const updateActivity = () => {
      const now = Date.now();
      lastActivityRef.current = now;

      if (now - lastUpdateRef.current >= UPDATE_INTERVAL) {
        lastUpdateRef.current = now;
        set(userLastActiveRef, now);
        set(userStatusRef, "online");
      }
    };

    events.forEach((event) => {
      window.addEventListener(event, updateActivity);
    });

    const updateInterval = setInterval(() => {
      const now = Date.now();
      if (now - lastActivityRef.current < UPDATE_INTERVAL) {
        set(userLastActiveRef, now);
        set(userStatusRef, "online");
        lastUpdateRef.current = now;
      }
    }, UPDATE_INTERVAL);

    const checkInactivity = setInterval(async () => {
      const now = Date.now();
      const timeSinceLastActivity = now - lastActivityRef.current;

      if (timeSinceLastActivity >= inactivityTimeout) {
        try {
          await handleManualLogout(user.uid);
          toast.info("Oturum süreniz doldu. Lütfen tekrar giriş yapın.");
        } catch (error) {
          console.error("Oturum kapatma hatası:", error);
        }
      }
    }, 10000);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      events.forEach((event) => {
        window.removeEventListener(event, updateActivity);
      });
      clearInterval(updateInterval);
      clearInterval(checkInactivity);
    };
  }, [user, inactivityTimeout]);

  // Manuel çıkış fonksiyonunu dışa aktar
  return {
    logout: async () => {
      if (user) {
        await handleManualLogout(user.uid);
        toast.success("Başarıyla çıkış yapıldı.");
      }
    },
  };
};

export default useSessionManager;
