import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import Footer from "examples/Footer";
import { useAccount } from "context/AccountContext";
import MDLoadingSkeleton from "components/MDLoadingSkeleton";
import MDErrorDisplay from "components/MDErrorDisplay";
import PropTypes from "prop-types";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import { usePosts } from "hooks/usePosts";
import { useState } from 'react';
import MDPagination from "components/MDPagination";

/**
 * PostsCategoryLayout component renders a layout for displaying posts
 * within a specific category. It handles loading, error, and pagination
 * states to ensure a smooth user experience.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.transparent - Determines the transparency styling.
 * @param {string} props.categoryId - The ID of the category whose posts are to be displayed.
 * @returns {JSX.Element} The rendered PostsCategoryLayout component.
 */
function PostsCategoryLayout({ transparent, categoryId }) {
  const [currentPage, setCurrentPage] = useState(1);
  const POSTS_PER_PAGE = 12; // Her sayfada gösterilecek post sayısı

  const { isLoading, error } = useAccount();
  const { postsCategory } = usePosts(categoryId);

  // Loading durumunda tüm grid için tek bir loading state
  if (isLoading) {
    return (
      <MDBox
        px={{
          xs: 1,
          sm: transparent ? 0 : 1,
          md: transparent ? 0 : 1,
          xl: transparent ? 0 : 1,
          lg: transparent ? 0 : 1,
        }}
        my={1}
        mx={2}
      >
        <Grid container spacing={2}>
          {[...Array(3)].map((_, index) => (
            <Grid item xs={12} lg={6} key={index}>
              <MDLoadingSkeleton />
            </Grid>
          ))}
        </Grid>
        <Footer />
      </MDBox>
    );
  }

  // Hata durumunda
  if (error) {
    return (
      <MDBox
        px={{
          xs: 1,
          sm: transparent ? 0 : 1,
          md: transparent ? 0 : 1,
          xl: transparent ? 0 : 1,
          lg: transparent ? 0 : 1,
        }}
        my={1}
        mx={2}
      >
        <MDErrorDisplay message={error} />
      </MDBox>
    );
  }

  const renderCategoryPosts = () => {
    if (!postsCategory) return null;

    // Sayfalama için postları böl
    const indexOfLastPost = currentPage * POSTS_PER_PAGE;
    const indexOfFirstPost = indexOfLastPost - POSTS_PER_PAGE;
    const currentPosts = postsCategory.slice(indexOfFirstPost, indexOfLastPost);

    return currentPosts.map((post) => (
      <Grid item key={post.id} xs={12} md={6} lg={4} mb={3}>
        <DefaultProjectCard
          image={
            post.featuredImageUrl ||
            "https://images.unsplash.com/photo-1497294815431-9365093b7371?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1950&q=80"
          }
          label={post.categoryName}
          title={post.title}
          description={post.excerpt}
          action={{
            route: `/post/${post.slug}`,
            label: "detay",
            color: "info",
            type: "internal",
          }}
          authors={[
            {
              image: post.authorAvatar,
              name: post.authorName,
            },
          ]}
        />
      </Grid>
    ));
  };

  const renderPagination = () => {
    if (!postsCategory) return null;

    const totalPages = Math.ceil(postsCategory.length / POSTS_PER_PAGE);

    // Eğer sadece 1 sayfa varsa pagination'ı gösterme
    if (totalPages <= 1) return null;

    return (
      <MDBox mt={4} display="flex" justifyContent="center">
        <MDPagination>
          {/* İlk sayfa butonu */}
          <MDPagination item
            onClick={() => setCurrentPage(1)}
            disabled={currentPage === 1}>
            <span>&laquo;</span>
          </MDPagination>

          {/* Önceki sayfa butonu */}
          <MDPagination item
            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}>
            <span>&lsaquo;</span>
          </MDPagination>

          {/* Sayfa numaraları */}
          {[...Array(totalPages)].map((_, index) => (
            <MDPagination item
              key={index + 1}
              onClick={() => setCurrentPage(index + 1)}
              active={currentPage === index + 1}>
              {index + 1}
            </MDPagination>
          ))}

          {/* Sonraki sayfa butonu */}
          <MDPagination item
            onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}>
            <span>&rsaquo;</span>
          </MDPagination>

          {/* Son sayfa butonu */}
          <MDPagination item
            onClick={() => setCurrentPage(totalPages)}
            disabled={currentPage === totalPages}>
            <span>&raquo;</span>
          </MDPagination>
        </MDPagination>
      </MDBox>
    );
  };

  return (
    <>
      <MDBox
        px={{
          xs: 1,
          sm: transparent ? 0 : 1,
          md: transparent ? 0 : 1,
          xl: transparent ? 0 : 1,
          lg: transparent ? 0 : 1,
        }}
        my={1}
        mx={2}
      >
        <MDBox mb={3}>
          <Grid container spacing={2}>
            {renderCategoryPosts()}
          </Grid>
          {renderPagination()}
        </MDBox>
      </MDBox>
    </>
  );
}

PostsCategoryLayout.defaultProps = {
  transparent: false,
  light: false,
  categoryId: null,
};

PostsCategoryLayout.propTypes = {
  transparent: PropTypes.bool,
  light: PropTypes.bool,
  categoryId: PropTypes.string,
};

export default PostsCategoryLayout;