// src/hooks/usePosts.js
import { useState, useEffect } from "react";
import { postService } from "../services/postService";

/**
 * usePosts hook
 *
 * This hook loads the posts from the server and keeps them in the component
 * state. The hook also provides a loading state and an error state that can be
 * used to display a loading indicator and an error message, respectively.
 *
 * The hook returns an object with four properties:
 *
 *   - posts: an array of post objects
 *   - postsLoading: a boolean indicating whether the posts are still being loaded
 *   - postsError: an error string if the posts failed to load
 *   - featuredPosts: an array of featured post objects
 *   - postsCategory: an array of post objects filtered by the given categoryId
 *
 * When the hook is called with a categoryId parameter, it filters the posts by
 * that category and puts the filtered posts in the postsCategory state. If the
 * categoryId parameter is not provided, then the hook will load all posts.
 *
 * The hook uses the useAsyncEffect hook to load the posts in parallel with the
 * featured posts. If the loading of the posts fails, the hook will set the
 * postsError state to the error message.
 */
export const usePosts = (categoryId = null) => {
  const [posts, setPosts] = useState([]);
  const [postsCategory, setPostsCategory] = useState([]);
  const [featuredPosts, setFeaturedPosts] = useState([]);
  const [postsLoading, setPostsLoading] = useState(true);
  const [postsError, setPostsError] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        // Load both regular and featured posts in parallel
        const [postsData, featuredPostsData] = await Promise.all([
          postService.getPosts(),
          postService.getFeaturedPosts(),
        ]);

        if (categoryId) {
          setPostsCategory(
            postsData.filter((post) => post.categoryId === categoryId)
          );
        }

        setPosts(postsData);
        setFeaturedPosts(featuredPostsData);
      } catch (err) {
        setPostsError(err.message);
      } finally {
        setPostsLoading(false);
      }
    };

    loadData();
  }, [categoryId]);

  return { posts, postsLoading, postsError, featuredPosts, postsCategory };
};
